import React from "react"
import styled from "styled-components"
import { Layout } from "../components"
import { Link } from "gatsby"

const error = () => {
  return (
    <Layout>
      <Wrapper>
        <section className="error-page">
          <div className="section-center">
            <span>404</span>
            <h3>
              申し訳ございません。
              <br />
              お探しのページは見つかりませんでした。
            </h3>
            <Link to="/" className="btn">
              ホームへ戻る
            </Link>
          </div>
        </section>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  .error-page {
    min-height: calc(100vh - 7rem);
    background: var(--clr-grey-10);
  }
  .error-page {
    background: var(--clr-grey-10);
    display: grid;
    place-items: center;
    text-align: center;
  }
  /* error page */
  .error-page {
    background: var(--clr-primary-10);
  }
  .error-page span {
    font-size: 10rem;
    color: var(--clr-black);
  }
  .error-page h3 {
    margin-bottom: 2rem;
    padding: 0 2rem;
  }
`

export default error
